import React from 'react'
import * as styles from '../assets/styles.module.scss'
import { default as Header } from '../components/header'
import { default as Footer } from '../components/footer'
import Heroshot from '../components/Heroshot'
import ModelGrid from '../components/ModelGrid'

const Index = () => {
    return(
        <section className={styles.Container}>
            <Header />
            <Heroshot />
            <div className={styles.Homepage}>
                <ModelGrid />
            </div>
            <Footer />
        </section>
    )
}

export default Index